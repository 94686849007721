<template>
  <v-container
    fluid
    class="d-flex flex-column justify-space-between px-2"
    style="height: 100%;"
  >
    <v-row
      no-gutters
      class="list-top"
    >
      <h3
        class="ml-1 mr-2"
        v-text="title"
      />
      <div
        class="d-flex flex-column justify-center align-center"
        style="height: 100%;"
      >
        <global-tooltip
          :class="'ma-2'"
          :text="tooltipText"
        />
      </div>
    </v-row>
    <v-row
      no-gutters
      class="d-flex flex-column justify-start overflow-y-auto py-2"
    >
      <v-list
        v-for="(item, index) in localItems"
        :key="index"
        dense
        :style="styleSettings.list"
      >
        <competition-crud-list-item
          :key="index+localItems.length"
          :item="item"
          :index="index"
          :disabled="disabledBtnAdd"
          @onUpdate="(itemToUpdate) => { localItems = localItems.map(e => e.id==item.id ? {...itemToUpdate}: e) }"
          @onDelete="(itemToDelete) => { localItems = localItems.filter(e => e.name !== itemToDelete.name); $emit('onChanged', localItems) }"
        />
      </v-list>
    </v-row>
    <v-row
      no-gutters
      style="flex: initial !important;"
      class="align-center"
    >
      <v-btn
        icon
        small
        color="primary"
        :disabled="disabledBtnAdd"
        @click="addElement"
      >
        <v-icon
          dark
        >
          add_box
        </v-icon>
      </v-btn>
      <span
        class="ml-2"
        v-text="addButtonText"
      />
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'CompetitionCrudList',
  components: {
    CompetitionCrudListItem: () => import('@/modules/competition/components/CompetitionCrudListItem'),
    GlobalTooltip: () => import('@/components/GlobalTooltip'),
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    tooltipText: {
      type: String,
      required: true,
    },
    addButtonText: {
      type: String,
      required: true,
    },
    edditButtonText: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      localItems: [],
      disabledBtnAdd: false,
    }
  },
  computed: {
    formatLabel() {
      return typeof this.listName === 'string' ? `${this.listName.charAt(0).toUpperCase()}${this.listName.slice(1)} (${this.data.length})` : ''
    },
    projectEditView() {
      // categories are added @settings component
      return this.view === 'project-edit'
    },
    structureView({ projectEditView, listName }) {
      return !projectEditView && listName !== 'categories'
    },
    styleSettings() {
      return {
        checkbox: {
          fontWeight: this.activeList ? '900' : '300',
          marginLeft: '2px',
          marginRight: '8px',
        },
        list: {
          background: 'white',
          border: '2px solid #dadada',
          borderRadius: '12px',
          boxShadow: `2px 2px 2px 1px ${this.$vuetify.theme.themes.light.inactive}`,
          maxWidth: '100%',
          height: '48px',
          margin: '2px',
          padding: '0px',
        },
      }
    },
  },

  async created() {
    this.localItems = [...this.items]
  },

  methods: {
    async saveChanges() {
      this.$emit('onChanged', this.localItems)
    },
    async addElement() {
      const item = {
        logo: null,
        name: '',
      }
      this.localItems.push(item)
      this.$emit('onChanged', this.localItems)
    },
    onUpdate(index, item) {
      this.localItems[index] = item
    },
  },
}
</script>

<style lang="scss" scoped>
.list-top {
  position: relative;
  flex: none !important;
}
</style>
